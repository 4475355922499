import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./privacy-policy-heading";
import Content from "./privacy-policy-content";

const PrivacyPolicy = () => (
  <>
    <SEO
      title={ContentObject.PrivacyPolicy.meta.title}
      description={ContentObject.PrivacyPolicy.meta.description}
    />

    <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
      <section data-csweb="privacy-policy">
        <Heading />
        <Content />
      </section>
    </Layout>
  </>
);

export default PrivacyPolicy;
