import React from "react";

import { Grid, Cell } from "../../components/ui";
import { ContentObject } from "../../components/content";

import { useStyletron } from "baseui";

import ReactMarkdown from "react-markdown";

const Content = () => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb="contact-form">
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              backgroundColor: theme.colors.backgroundPrimary,
              marginTop: `-${theme.sizing.scale3200}`,
              height: theme.sizing.scale3200,
              [theme.breakpoints.mediaQueries.large]: {
                marginLeft: "-32px",
                marginRight: "-32px",
              },
            })}
          ></div>
        </Cell>

        <Cell skip={[0, 0, 2]} span={[4, 8, 8]}>
          <div
            className={css({
              marginTop: `-${theme.sizing.scale3200}`,
              paddingTop: theme.sizing.scale1400,
              paddingBottom: theme.sizing.scale2400,
              color: theme.colors.contentPrimary,
            })}
          >
            <ReactMarkdown source={ContentObject.PrivacyPolicy.content} />
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Content;
